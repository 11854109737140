import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';


export default function MainNavbar () {
    const location = useLocation();
    return (
        <div className="d-flex justify-content-center nav-container">
        <Navbar className="nav-main col-12 clamp-h" expand="sm">
        <Container>
          {
            location.pathname !== "/" ?
            <Navbar.Brand className="me-auto"><Link className="text-center" to="/">Home</Link></Navbar.Brand>
            :
            <Navbar.Brand className="me-auto" />
          }
          <Navbar.Toggle aria-controls="basic-navbar-nav"><FontAwesomeIcon icon={solid('bars')} /></Navbar.Toggle>
          <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
            <Nav className="justify-content-center">
              <Nav.Link as="div"><Link className="text-center" to="/services"><FontAwesomeIcon icon={solid('list-check')}/> Services</Link></Nav.Link>
              <NavDropdown title={<><FontAwesomeIcon icon={solid('circle-info')}/> About</>} id="basic-nav-dropdown">
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/about#about">What is a Notary?</HashLink>
                </NavDropdown.Item>
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/about#whoami">About Me</HashLink>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title={<>Legal Notices</>} id="basic-nav-dropdown">
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/notices#fees">Fees</HashLink>
                </NavDropdown.Item>
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/notices#termsofbusiness">Terms of Business</HashLink>
                </NavDropdown.Item>
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/notices#regulatoryinfo">Regulatory Information</HashLink>
                </NavDropdown.Item>
                <NavDropdown.Item as="div">
                    <HashLink smooth to="/notices#privacy">Privacy Policy</HashLink>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav>
              <Nav.Link as="div"><Link className="text-center" to="/contact"><FontAwesomeIcon icon={regular('envelope')}/> Contact</Link></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
    )
}
/*
<div className="d-flex justify-content-center nav-container">
<nav className="row justify-content-center clamp-width align-items-start navbar navbar-expand-sm">
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon" />
  </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <div className="nav-item col-3 text-center"><Link className="text-center" to="/"><FontAwesomeIcon icon={solid('home')}/> Home</Link></div>
    <Dropdown className="nav-item col-3 text-center">
      <Dropdown.Toggle as="a" variant="success" id="dropdown-basic">
        <FontAwesomeIcon icon={solid('circle-info')}/> About
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as="div"><HashLink to="/about#about"> What is a Notary?</HashLink></Dropdown.Item>
        <Dropdown.Item as="div"><HashLink to="/about#whoami">About Us</HashLink></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    <div className="nav-item col-3 text-center"><Link className="text-center" to="/services"><FontAwesomeIcon icon={solid('list-check')}/> Services</Link></div>
    <div className="nav-item col-3 text-center"><Link className="text-center" to="/contact"><FontAwesomeIcon icon={regular('envelope')}/> Contact</Link></div>
  </div>
</nav>
</div>
<hr className="divider-thin my-0" />
*/