import Section from "./Section"

export default function Services() {
    return (
        <div className="min-vh">
        <Section>
            <h1 className="d-inline-block color-primary min-h my-5">Services for Businesses</h1>
            <p>
                I can certify all kinds of commercial documents that may be required to carry
                out business abroad including the examples below but this is by no means
                exhaustive so please contact me to discuss your requirements. Translations
                can be arranged for any country in the world.
            </p>
            <ul>
                <li>
                    Power of Attorney from companies for all kinds of commercial reasons e.g.
                    creating a subsidiary, renewing a patent, conducting litigation, representing
                    the English company overseas
                </li>
                <li>
                    Confirmation of the existence of the English company - attaching certificates
                    of incorporation, Certificates of Good Standing, details of the company
                    officers etc.
                </li>
                <li>Notarising affidavits and statutory declarations for foreign Court proceedings</li>
                <li>Certified copy passports of company officers</li>
                <li>Confirmation of accreditations for export purposes</li>
                <li>Arrange legalisation</li>
            </ul>
        </Section>
        <Section className="grey">
            <h1 className="d-inline-block color-primary min-h my-5">Services for Individuals</h1>
            <p>
                A Notary Public can certify all kinds of documents including those listed here. Please contact me to
                discuss your requirements. Translations for any country in the world can also be arranged.
            </p>
            <ul>
                <li>
                    Power of Attorney (giving someone abroad authority to act on your behalf) e.g. for property
                    purchase/sale; litigation; dealing with other official bodies e.g. government agencies, banks
                </li>
                <li>
                    Notarising documents relating to the purchase or sale of property abroad
                </li>
                <li>Notarising documents allowing individuals to marry abroad</li>
                <li>Notarising wills and other documents relating to a deceased person's estate</li>
                <li>
                    Certified copy documents e.g. birth, death and marriage certificates, copy passport pages,
                    qualifications, documents supporting adoption applications from abroad
                </li>
                <li>
                    Confirmation of facts e.g. declaration of no criminal convictions; declaration of no impediment to
                    marriage etc.
                </li>
                <li>Notarising child travel consents</li>
                <li>Statutory declarations</li>
                <li>Asset transfers e.g. shares</li>
                <li>Arrange legalisation</li>
            </ul>
        </Section>
        <Section className="">
            <h1 className="d-inline-block color-primary min-h my-5">What is legalisation?</h1>
            <p>
                Legalisation is the process whereby a Notary’s signature and seal are verified and confirmed by
                an independent authority. Many countries require the Apostille - a certificate of confirmation
                provided in the UK by the Foreign & Commonwealth Office (FCO).
            </p>
            <p>
                I can organise legalisation for you. The FCO's standard service currently costs £30 per
                document and takes 3/4 days. The premium service costs £75 per document and is useful for
                very urgent documents as it usually saves at least 1 day.
            </p>
            <p>
                Some countries require legalisation by their own Consulate in England. Consular fees vary
                enormously from £10 to £400 depending on the type of document legalised and the Consulate
                involved.
            </p>
            <p>
                Once legalised, the document will usually be accepted (subject to any necessary translation) by
                the authorities in the destination country as evidence of the matters stated in the document.
            </p>
            <p>
                Allowing more time for legalisation keeps the costs of legalisation down.
            </p>
        </Section>
        </div>
    )
}
