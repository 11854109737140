import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import Carousel from 'react-bootstrap/Carousel';

const REVIEWS = [
    {
        author: 'Alison',
        review: 'I needed documents verified urgently, by a Notary Public, and was lucky enough to find Bronwyn, from BW Notary, through my internet search. From the moment Bronwyn answered my call I felt reassured that I was in professional hands. She went \'above and beyond\' to fit in with my requirements, along with clearly explaining which documents were required. Bronwyn visited our home, which also helped me immensely, she was highly organised, extremely professional and very friendly. Highly recommend her service. Thank you Bronwyn.',
    },
    {
        author: 'Lucia',
        review: 'Our whole experience was fantastic from beginning to end. My partner and I needed a notary to legalise some documents for us, so we got in touch with Bronwyn. Not only did she respond quickly, but she also gave us all the information we needed and was available to answer any questions about the process. We had little time and were concerned we wouldn’t be sorted in time for our move, but Bronwyn moved everything along so quickly and efficiently, we received our documents back within two weeks. She was extremely friendly and helpful, and I couldn’t recommend her enough!',
    },
    {
        author: 'Caitlin',
        review: 'I highly recommend Bronwyn as a public notary. I would give her 10 stars if I could. We needed a document publicly notarised urgently to send overseas to the US. Bronwyn was so understanding to the unique needs of our situation and went above and beyond to help us out. She made herself available, answering all my questions, and offered us an appointment the next day. She was incredibly knowledgeable around the legal requirements for the document and took extra measures to ensure overseas courts were unlikely to reject it. Her staff were also very warm and welcoming.',
    },
    {
        author: 'Zara',
        review: 'Bronwyn was very professional, and managed to fit in an appointment with us very quickly. She is very knowledgeable in her field, and was able to answer any additional questions we had. Would highly recommend!'
    },
    {
        author: 'Matthew',
        review: 'Bronwyn helped notarise some documents for me. She was very prompt to respond to my initial email and offered a quick turn around. I’d highly recommend her and I’ll no doubt be back for any future requirements.',
    },
]

export const Testimonials = () => {
    return (
        <Carousel slide indicators={false}>
        {REVIEWS.map(({ author, review }, i) => {
            return (
                <Carousel.Item interval={20000}>
                    <div className="review-item">
                        <div className="review-content">
                            <p>
                                <FontAwesomeIcon icon={solid('quote-left')} />
                                <span className="review-text" style={{padding: '0 5px 0 5px'}}>{review}</span>
                                <FontAwesomeIcon icon={solid('quote-right')} />
                            </p>
                            <p><FontAwesomeIcon icon={solid('minus')} /> {author}</p>
                        </div>
                    </div>
                    
                </Carousel.Item>
            );
        })}
        </Carousel>
    )
}