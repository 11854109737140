import Section from "./Section"


export const ContactForm = () => {
    return (
        <Section>
            <div className="min-h"><h1 className="">Get in touch</h1></div>
            <br />
            <div className="d-flex flex-wrap">
                <div className="col-12 col-md-6" style={{ padding: '0 10px 0 10px' }}>
                    <p>
                        For all enquiries, please email us at <a href='mailto:bronwyn@bwnotary.co.uk'>bronwyn@bwnotary.co.uk</a> or
                        call on <a href="tel:07423124675">+44 (0) 742 312 4675</a>.
                    </p>
                    <p>
                        Please include your full name and contact details attaching any relevant
                        documentation with your enquiry.
                    </p>
                    <p>
                        Bronwyn's office is at Dutton Gregory LLP in Woking. On-site client parking is
                        available. Bronwyn also provides mobile notarial services accross Surrey and
                        the surrounding areas. In person meetings are available by appointment only.
                    </p>
                    <p>
                        <b>Address:</b>
                    </p>
                    <span style={{ display: 'block', marginLeft: '10px' }}>Dutton Gregory</span>
                    <span style={{ display: 'block', marginLeft: '10px' }}>24 Church Street West</span>
                    <span style={{ display: 'block', marginLeft: '10px' }}>Woking, Surrey GU21 6HT</span>
                    <br />
                </div>
                <div className="col-12 col-md-6">
                <iframe
                        height="500"
                        className="map"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen
                        title="BW Notary Map"
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ0UEca0LXdUgR5P_AgDFYYvM&zoom=10&key=AIzaSyBhL8aRGLte8NIxuyWgY-jDh7LhQgdwMhE"
                        >    
                    </iframe>
                </div>
            </div>

            {/* <Form className="form-container">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formText">
                    <Form.Label>Enquiry</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="..." />
                </Form.Group>
                <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon={solid('envelope')} fixedWidth /> Submit
                </Button>
            </Form> */}
        </Section>
    )
}


export default function Contact() {
    return (
        <ContactForm />
    )
}