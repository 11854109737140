import { HashLink } from 'react-router-hash-link';

export default function Footer() {
    return (
        <footer>
            <div className="d-flex flex-wrap footer-items justify-content-between">
                <div className="mx-3 col-md-3 col-sm-5 my-2 font-lc">
                    <span className="font-lc font-white d-block">Bronwyn White is a member of <a href="https://www.thenotariessociety.org.uk/" target="_blank" rel="noreferrer">The Notaries Society</a></span>
                    <span className="font-lc font-white d-block"><a href="/privacy_notice.pdf" target="_blank" rel="noreferrer">Privacy Notice</a></span>
                    <span className="font-lc font-white d-block"><a href="/terms_and_conditions.pdf" target="_blank" rel="noreferrer">Terms of Business</a></span>
                    <span className="font-lc font-white d-block"><HashLink to="/notices#regulatoryinfo">Regulatory Information</HashLink></span>
                    <span className="font-lc font-white d-block" style={{ fontSize: '12px' }}><i>Regulated through the <a className="font-lc" href="https://www.facultyoffice.org.uk/" target="_blank" rel="noreferrer">Faculty Office</a> of the Archbishop of Canterbury</i></span>
                </div>
                <div className="mx-3 col-md-3 col-sm-5 my-2 font-lc">
                    <div style={{ textAlign: 'center' }}>
                    <span className="font-lc font-white d-block">Telephone: <a className="font-lc" href="tel:07423124675">+44 (0) 742 312 4675</a></span>
                    <span className="font-lc font-white d-block">Email: <a className="font-lc" href="mailto:bronwyn@bwnotary.co.uk" target="_blank" rel="noreferrer">bronwyn@bwnotary.co.uk</a></span>
                    </div>
                </div>
                <div className="mx-3 col-md-4 col-sm-10 my-2 font-lc">
                    <span className="font-lc font-white d-block">Dutton Gregory</span>
                    <span className="font-lc font-white d-block">24 Church Street West</span>
                    <span className="font-lc font-white d-block">Woking, Surrey GU21 6HT</span>
                    <span className="font-lc font-white" style={{ fontSize: '12px' }}>
                    <i>
                        For the avoidance of any doubt, 
                        whilst Bronwyn's office is based at Dutton Gregory LLP in Woking, Bronwyn's
                        notarial work is undertaken by "B.W. Notary" which is completely independent
                        of Dutton Gregory LLP.
                    </i>
                    </span>
                </div>  
            </div>
            
        </footer>
    )
}