import Section from "./Section"


export default function Notices() {
    return (
        <>
        <Section>
                <div id="fees"/>
                <h1 className="d-inline-block color-primary my-5 min-h">Fees</h1>
                <p>
                    If the matter is simple, I will endeavour to charge a fixed fee to include disbursements such
                    as legalisation fees, postage, consular agent fees, courier fees, travelling expenses, translation
                    costs, etc.
                </p>
                <p>
                    For more complex or time-consuming matters, the fee will be based on my hourly rate, which I
                    will advise at the beginning of the transaction. The fee charged will include time spent on
                    preliminary advice, drafting and preparation time, making and receiving telephone calls, 
                    correspondence written and received in all formats, arranging legalisation, and record keeping. 
                </p>
                <p>
                    Each notarial act is different, so your legal fee will depend on the services you require.
                    Once I have better understanding of what is required, I will be able to provide you with an
                    estimate of costs before comencing any work. 
                </p>
        </Section>
        <Section className="grey">
                <div id="termsofbusiness"/>
                <h1 className="d-inline-block color-primary my-5 min-h">Terms of Business</h1>
                <p>
                    My <a href="/terms_and_conditions.pdf" target="_blank" rel="noreferrer">Terms of Business</a> set out the terms of the contract of the provision of my services
                    that will be formed between us when you instruct me as a notary. Please note that the
                    contract creates legally binding obligations upon each of us. This contains information on
                    how I charge, service information, complaints and regulatory information. Please read my Terms
                    of Business carefully and let me know if you have any questions before we proceed.
                </p>
                <p>
                    Clients and persons that have been refused notarial services have the right to make a
                    complaint about the notary who provided (or refused to provide) those services, and to
                    have that complaint resolved. My complaints proceedure is enclosed within my <a href="/terms_and_conditions.pdf" target="_blank" rel="noreferrer">Terms of Business</a>.
                    You also have the right to cancel your contract in accordance with the law of England and Wales.
                </p>
                <p>
                    Should you remain dissatisfied at the conclusion of my complaints proceedure, you have the right
                    to take your complaint to the <a href="https://www.legalombudsman.org.uk/" target="_blank" rel="noreferrer">Legal Ombudsman</a> within six months of my final response.
                </p>
        </Section>
        <Section className="">
                <div id="regulatoryinfo" />
                <h1 className="d-inline-block color-primary my-5 min-h">Regulatory Information</h1>
                <p>
                    As a Notary, my practice is regulated through 
                    the <a href="https://www.facultyoffice.org.uk/" target="_blank" rel="noreferrer">Faculty Office</a> of the Archbishop of Canterbury. 
                    The Notaries Practice Rules 2019 can be
                    found <a href="https://www.facultyoffice.org.uk/wp-content/uploads/2019/11/Notaries-Practice-Rules-2019.pdf" target="_blank" rel="noreferrer">here</a>.
                    Your data may retained and used in accordance with my data protection policy as part of my Terms of Business. 
                </p>
                <br />
        </Section>
        <Section className="grey">
                <div id="privacy" />
                <h1 className="d-inline-block color-primary my-5 min-h">Privacy Policy</h1>
                <p>
                    As a Data Controller, I am registered with the <a href="https://ico.org.uk/" target="_blank" rel="noreferrer">Information Commissioner's Office</a>.
                </p>
                <p>
                    In the course of providing my services, I am likely to collect personal data for the purposes of assisting you
                    with your requirements. Further details may be found in the full version of my <a href="/privacy_notice.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>.
                    When instructing me to act for you, you will acknowledge that I may receive from you and permanently hold in my 
                    records information that may include your: full name, date of birth, address, contact details, place of birth,
                    marital status, domicile, family relations' details, occupation, pension details, and relevant medical information.
                </p>
                <p>
                    The purpose of collecting this data is to provide you with notarial services.
                </p>

</Section>
        </>
    )
}