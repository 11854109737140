import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Services from './Services';
import Notices from './Notices';
import Footer from './Footer';
import Navbar from './Navbar';
import bg from "./bg.jpg"


function App() {
  return (
    <div style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', height: '100vh', width: '100vw', overflowY: 'scroll', overflowX: 'hidden' }}>
      {/* <img src={bg} className="bgImg" /> */}
      <Router>
        <div className="app-container">
          <Navbar />
          <div className="main-content col-12">
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/notices" element={<Notices />} />
            <Route path="/" element={<Home />} />
          </Routes>
          </div>
          {/* <hr className="divider clamp-width mx-auto" /> */}
          <Footer />
        </div>
      </Router>
    </div>
  );
}

export default App;
