import Section from "./Section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import bw from './bronwyn_white.jpg'


export default function About() {
    return (
        <>
        <Section>
                <h1 className="d-inline-block color-primary my-5 min-h">What is a Notary?</h1>
                
                <p>
                A Notary Public is a legal officer of ancient standing. 
                The functions of Notaries include the preparation and execution of legal 
                documents for use abroad, attesting the authenticity of deeds and writings, 
                and protesting bills of exchange.
                </p>
                <p>
                The admission and regulation of Notaries Public in England & Wales is one of the 
                functions of the Faculty Office. The Master of the Faculties (the judge who presides 
                over the Faculty Office) is the approved regulator of the profession. This jurisdiction 
                regarding the Notarial Profession was confirmed and enhanced by the Courts and Legal 
                Services Act of 1990 and the Legal Services Act 2007. Both confirmed the Master’s 
                statutory powers to make Rules for the regulation of the profession.
                </p>
                <p>
                There are two membership organisations for Notaries, the Notaries Society and 
                the Society of Scrivener Notaries. They have representative functions only and 
                are not part of the Faculty Office.
                </p>
                <p>
                Although The Faculty Office of the Archbishop of Canterbury regulates Notaries 
                (and the ‘faculty’ appointing a Notary is issued in the name of the Archbishop), 
                a Notary is a secular lawyer and is not otherwise associated with the Archbishop or the Church of England.
                </p>
                <p>
                The Faculty Office of the Archbishop of Canterbury also maintains jurisdiction 
                for the appointment (and partial regulation) of Notaries in certain overseas jurisdictions. 
                See <a href="https://www.facultyoffice.org.uk/notaries/customers/what-is-a-notary/" target="_blank" rel="noreferrer">
                    here <FontAwesomeIcon icon={solid('external-link-alt')}/>
                </a> for further information.
                </p>
                <br />
            <div id="whoami" />
                <h1 className="d-inline-block color-primary my-5 min-h">About Me</h1>
                <div className="d-flex justify-content-center flex-wrap flex-wrap">
                    <div className="col-12 col-md-9">
                        <p>
                            I qualified as a Notary Public for England and Wales, having completed my
                            studies at University College London. I am also a qualified solicitor having read law at the University of Bristol and
                            following the completion of the Legal Practice Course at the University of
                            Law in Guildford.
                        </p>
                        <p>
                            Based in Woking, I offer notarial services in across Surrey and in the
                            surrounding areas. Appointments are made at our office in Woking or arrangements can be to
                            meet you at home or at your office.
                        </p>
                    </div>
                    <div className="col-4 col-md-3">
                        <img src={bw} alt="" className="bwHead d-block"/>
                    </div>
                </div>
        </Section>
        </>
    )
}