import { useEffect, useRef, useState, useCallback } from "react"

const Section = ({children, d = 0, left = false, ...props}) => {
    const [show, setShow] = useState(false)
    const ref = useRef(null)

    const intersetCallback = useCallback((entries) => {
        const [ entry ] = entries
        if (entry.isIntersecting) {
            setTimeout(() => setShow(true), d)
        }
    }, [setShow, d])

    useEffect(()=>{
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }
        // const obj = ref.current
        const observer = new IntersectionObserver(intersetCallback, options)
        const currentRef = ref.current
        if (currentRef) observer.observe(currentRef)

        return () => {
            if (currentRef) observer.unobserve(currentRef)
        }
    }, [ref, intersetCallback])
    const hiddenClass = `${left ? 'hidden-left sect-hidden' : 'sect-hidden'}`
    const showClass = 'sect-show'
    return (
        <section {...props} ref={ref}><div className="clamp-h"><div className={show ? showClass : hiddenClass}>{children}</div></div></section>
    )
}

export default Section
