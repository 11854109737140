import Section from "./Section"
import { Testimonials } from "./Testimonials"
import heading from "./wordmark_white.svg"
import crest from "./crest_white.svg"

export default function Home() {
    return (
        <div>
            <Section className="grey">
                <div className="row">
                    <img className="col-2" height={100} src={crest} alt="B. W. Notary crest" aria-label="B. W. Notary crest" />
                    <img className="col-10" height={100} src={heading} alt="B. W. Notary" aria-label="B. W. Notary" />
                </div>
            </Section>
            <Section>
            <h1>Why might you need a Notary Public?</h1>
            <br />
<p>
If you are an individual or business with assets or legal interests overseas you
may need to sign documents here and send them overseas rather than travel
abroad yourself. In some instances, a Notary Public's services can also be
used for legal matters in England & Wales.</p>
<p>
The person (or organisation) receiving the documents must be able to rely on
them with confidence and the Notary's signature and seal inspires that
confidence because English Notaries are recognised worldwide.</p>
<p>
Notaries are qualified lawyers and most, like me, are also qualified solicitors,
trained to ensure that a document meets your needs both here and
overseas.</p>
            </Section>
            <Section className="grey">
                <div><h1>Why use me?</h1></div>
                <br />
                <p>I qualified as a Notary Public for England and Wales, having completed my
studies at University College London. I am also a qualified solicitor having read law at the University of Bristol and
following the completion of the Legal Practice Course at the University of
Law in Guildford.
</p>
<p>
Based in Woking, I offer notarial services across Surrey and in the
surrounding areas. Appointments are made at our office in Woking or arrangements can be to
meet you at home or at your office.
</p>
<p>
Appointments are made by arrangement only but I am flexible and may be
able to see you outside of normal office hours.
                </p>
            </Section>
            <Section className="grey">
                <br />
                <div id="reviews" className="reviews">
                <div className="col-12">
                <Testimonials />
                </div>
                </div>
            </Section>
        </div>
    )
}

